import { useState, useContext, useEffect } from 'react'
import { Modal, Card, Box, Container } from '@mui/material'
import { forceCheck } from 'react-lazyload'
import ListingDetail from '../Pages/ListingDetail'
import { CollectionContext } from '../Contexts/CollectionContext'

export const modalStyles = {
  position: 'absolute',
  top: '3%',
  width: '100%',
}

const ListingModal = ({ match, history, previousLocation }) => {
  const [isOpen, setIsOpen] = useState(true)
  const { setCurrentListing, currentListingIdentifier } = useContext(CollectionContext)

  useEffect(() => {
    if (currentListingIdentifier !== match.params.id) {
      setCurrentListing(match.params.id)
    }
  }, [match.params.id])

  const handleGoBack = e => {
    if (e) e.stopPropagation()

    setIsOpen(false)
    history.push(previousLocation.pathname)
  }

  return (
    <Modal open={isOpen} onClose={handleGoBack} sx={modalStyles}>
      <Container maxWidth="lg">
        <Card
          id="listing-modal"
          sx={{
            maxHeight: '97vh',
            overflowY: 'scroll',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
          }}
          // Makes lazy loaded components appear on page
          onScroll={forceCheck}
        >
          <Box
            sx={theme => ({
              backgroundColor: theme.colors.alpha.black[5],
            })}
          >
            <ListingDetail match={match} isModal onCloseModal={handleGoBack} />
          </Box>
        </Card>
      </Container>
    </Modal>
  )
}

export default ListingModal
