import { Stack, Typography, Box } from '@mui/material'

const UnavailableListing = () => (
  <Box p={3} py={16} minHeight="80vh">
    <Stack
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={2}
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography variant="h3">Listing unavailable.</Typography>
      <Typography variant="h5">
        The listing may no longer be available, you may not have access, or there may have been an
        error.
      </Typography>
      <Typography variant="h6">The details have been reported to our team.</Typography>
    </Stack>
  </Box>
)

export default UnavailableListing
