import LockIcon from '@mui/icons-material/Lock'
import {
  Box,
  Button,
  Typography,
  Stack,
  CardContent,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useContext } from 'react'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'

const Unauthorized = ({ listing }) => {
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)

  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      id="listing-detail"
      key={listing.id}
      p={3}
      py={isSmallerThanSm ? 3 : 26}
      display="flex"
      justifyContent="center"
    >
      <RsCard cardSx={{ boxShadow: 'none', maxWidth: 'fit-content' }}>
        <CardContent sx={{ p: 3 }}>
          <Box textAlign="center">
            <Box display="flex" justifyContent="center">
              <Box maxWidth="fit-content" sx={theme => ({ borderRadius: theme.spacing(2) })}>
                <LockIcon color="error" sx={{ fontSize: '48px' }} />
                <Typography variant="h4" fontWeight="bold" pt={1}>
                  Access Denied
                </Typography>
              </Box>
            </Box>
            <Box py={3}>
              <Typography variant="h1" pb={1}>
                {listing.address}, {listing.city}
              </Typography>
              <Typography color="text.secondary">
                {listing.mls_display_name}, #{listing.mls_number}
              </Typography>
            </Box>
            <Typography variant="body1">
              It looks like you're attempting to view a listing that you don't have access to.
            </Typography>
            <Typography variant="body1" mb={2}>
              This could be due to this listing's permissions, a change in data-feed access, or
              using an old/invalid link.
            </Typography>
            {!loggedIn && (
              <Box>
                <Typography variant="body1" mb={2}>
                  You may need to login to view this listing.
                </Typography>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Button variant="contained" href="/users/sign_in" sx={{ px: 16 }}>
                    Sign In
                  </Button>
                </Stack>
                <Typography color="text.secondary" pt={1}>
                  Don't have an account?{' '}
                  <Link href="#" onClick={showSignUp}>
                    Sign up
                  </Link>
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </RsCard>
    </Box>
  )
}
export default Unauthorized
