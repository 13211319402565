import { Box, Stack, Grid, useTheme, useMediaQuery } from '@mui/material'
import RsSkeletonLoading from '@/components/Common/UIComponents/PageLayout/RsSkeletonLoading'

const LoadingListing = () => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box>
      <Box height="60px" />
      <Stack
        direction="column"
        spacing={3}
        sx={theme => ({ p: 3, backgroundColor: theme.colors.alpha.white[100] })}
      >
        <RsSkeletonLoading height={isSmallerThanSm ? '200px' : '450px'} />
        <RsSkeletonLoading height={isSmallerThanSm ? '100px' : '150px'} />
      </Stack>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <RsSkeletonLoading height="250px" />
          </Grid>
          <Grid item xs={12} sm={5}>
            <RsSkeletonLoading height="250px" />
          </Grid>
          <Grid item xs={12} sm={7}>
            <RsSkeletonLoading height="250px" />
          </Grid>
          <Grid item xs={12} sm={5}>
            <RsSkeletonLoading height="250px" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default LoadingListing
